.App {
  .App {
    text-align: center;
    background-color: #b3a5a5;
    background-size: cover; /* cover ensures the image covers the entire element */
    background-position: center; /* centers the image in the element */
    background-repeat: no-repeat; /* prevents the image from repeating */
  }
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 15s linear;
  } */
/* } */

.welcome-message h1 {
  padding-top: 50px;
  color: #276f38; /* A blue that complements the React logo color */
  font-size: 2rem; /* Adjust size as necessary */
  text-align: center;
}

.welcome-message p {
  padding-top: 50px;
  color: #282c34; /* A dark shade for readability */
  font-size: 1.2rem; /* Adjust size as necessary */
  max-width: 600px; /* Ensures the text is not too wide */
  line-height: 1.5; /* Improves readability */
}
.about-section{
  
  background-size: cover;
  background-position: left;

}

/* General styles for sections */
.tokenomics-section, .about-section {
  
  color: #333; /* Dark grey for high contrast */
  padding: 20px;
  margin: 20px 0;
  
  /* background: linear-gradient(145deg, #a26e6e, #7fdd90);  */
}


.tokenomics-section h2, .about-section h2 {
  text-align: center;
  color: #276f38; /* Even darker shade for headings */
  margin-bottom: 10px;
}

.tokenomics-section p, .about-section p {
  font-size: 1.2rem;
  color: #282c34;
  line-height: 1.6; /* Improves readability */
}
.tokenomics-list {
  list-style: none; /* Remove default list styling */
  padding-left: 0; /* Align items to the left */
  margin: 20px 0; /* Spacing around the list */
  text-align: ;
}

.tokenomics-list li {
  margin-bottom: 10px; /* Space between list items */
  padding-left: 20px; /* Space for custom bullet or number */
  position: relative; /* For absolute positioning of custom bullet/number */
  font-size: 1.5rem; /* Adjust according to your design */
  line-height: 1.5; /* Better readability */
}

.tokenomics-list li::before {
  content: '•'; /* Custom bullet symbol, can be changed */
  color: #61dafb; /* Bullet color, choose what fits your design */
  font-size: 1.5em; /* Bigger bullet size */
  position: absolute;
  left: 0; /* Align bullet to the left side */
  top: 50%;
  transform: translateY(-50%); /* Center bullet vertically */
}

.tokenomics-list li:hover {
  padding-left: 25px; /* Slight movement on hover for interactivity */
  transition: padding-left 0.3s ease-in-out; /* Smooth transition for hover effect */
}


/* If you want a specific style for links */
.tokenomics-section a, .about-section a {
  color: #276f38; /* Bootstrap default blue for links, adjust as needed */
  text-decoration: none; /* Removes underline */
}

.tokenomics-section a:hover, .about-section a:hover {
  text-decoration: underline; /* Adds underline on hover for clarity */
}

/* Additional styling to enhance readability */
.tokenomics-section, .about-section {
  max-width: 800px; /* Keeps the text from spanning too wide */
  margin: 20px auto; /* Centers the section */
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); 
}


.App-logo {
  height: 40vmin;
}  
.App-header {
  background-color: #282c34;
  min-height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}



/* .App-text{
  text-align: center;
  color:rgb(0, 0, 0);
  font-size: xx-large;
} */
.App-body {
  background-color: #f1ede02d;
  min-height: 600px;
  display: flex;
  justify-content: center;
  
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* App.css */

/* Other styles... */

/* App.css */

/* Other styles... */

nav {
  background-color: #ffffff; 
  padding: 1rem;
  text-align: right;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

nav li {
  display: inline; /* Display list items inline to make nav horizontal */
  margin-right: 20px; /* Space between the buttons */
}

nav a {
  background-color: #276f38; /* Button color, matches React logo color */
  color: white; /* Button text color */
  padding: 5px 10px;
  text-decoration: none; /* Removes underline from links */
  border-radius: 5px; 
  font-weight: bold; /* Make font bold */
  transition: background-color 0.3s, color 0.3s; /* Smooth background and text color transition */
}

nav a:hover {
  background-color: #071721; /* Button hover color */
  color: brown; /* Button text hover color */
}

/* Additional styles... */


/* Additional styles... */
.footer {
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 40px; /* Adjust the space between images */
  padding: 20px;
  background-color: #f0f0f0; /* Light background for the footer */
}

.footerImage {
  width: 50px; /* Adjust based on your preference */
  height: auto;
  cursor: pointer; /* Change cursor on hover to indicate clickable */
  transition: transform 0.2s; /* Smooth transition for hover effect */
}

.footerImage:hover {
  transform: scale(1.1); /* Slightly enlarge images on hover */
}
